import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Consulting, digital services, and holdings." />
    <div className={'inline-block'}>
      <h1 className={'font-extrabold text-4xl md:text-6xl'}>P.F. Ventures, LLC.</h1>
      <p className={'text-base md:text-2xl font-light -mt-1'}>Consulting, digital services, and holdings.</p>
      <a
        href={'mailto:contact@pf.ventures'}
        className={'border-solid border rounded-lg border-white py-2 px-4 md:py-3 md:px-5 hover:bg-white hover:text-black mt-12 inline-block'}
        style={{ transition: 'background 0.3s, color 0.3s' }}
      >
        Contact
      </a>
    </div>
  </Layout>
)

export default IndexPage
